<template>
  <div>
    <title-bar :title="$t('navigations.skillMatrix')" />
    <div v-if="rows" class="custom-table">
      <advanced-filter
        :fields-array="filterFields"
        :action="showFilterModal"
        @clearAction="showFilterModal = false"
        @paramsChange="setAdvancedParams"
      />
      <table-actions
        :actions="['search', 'addBtn', 'actionBtn', 'perPage', 'advancedFilter']"
        :actionBtnText="$t('messages.export')"
        :addBtnText="$t('messages.addNew')"
        :addBtnLink="$helper.getAddUrl('skill-matrix')"
        :option="filterOption"
        :options="filterOptions"
        :entries="pages"
        @actionBtnClicked="exportSkillMatrices"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        @filterChange="showFilterModal = true"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="rows"
        :sortable="sortable"
        @do-search="setSort"
        @is-finished="isLoading = false"
      >
        <template v-slot:name="data">
          {{
            data.value.employee.givenName + " " + data.value.employee.familyName
          }}
        </template>
        <template v-slot:number="data">
          {{ data.value.employee.number }}
        </template>
        <template v-slot:skill="data">
          {{ data.value.skill.skill }}
        </template>
        <template v-slot:skillNo="data">
          {{ data.value.skill.skillNo }}
        </template>
        <template v-slot:level="data">
          {{ data.value.level }}
        </template>
        <template v-slot:expiryDate="data">
          {{ $helper.formatDate(data.value.expiryDate) }}
        </template>
        <template v-slot:createdAt="data">
          {{ $helper.formatDate(data.value.createdAt) }}
        </template>
        <template v-slot:updatedAt="data">
          {{ $helper.formatDate(data.value.updatedAt) }}
        </template>
        <template v-slot:category="data">
          {{
            $helper.getEnumTranslation(
              "SKILL_CATEGORY",
              data.value.skill.category,
              $i18n.locale
            )
          }}
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('skill-matrix', data.value.id)" />
            <delete-btn @pressDelete="deleteResource(data.value['@id'])" />
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

  <script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import moment from "moment/moment";
import {useToast} from "vue-toastification";
import Toast from "@/components/Toast.vue";

export default {
  name: "SkillMatrix",
  components: {
    TableLite,
    TablePagination,
    DeleteBtn,
    TableActions,
    EditBtn,
    TitleBar,
    AdvancedFilter,
  },
  mixins: [ResourceUtils],
  data() {
    return {
      search: "",
      isLoading: false,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      activeStatus: {},
      showFilterModal: false,
      sortable: {
        order: "expiryDate",
        sort: "asc",
      },
      filterFields: [
        {
          label: "forms.createdAt",
          name: "createdAt",
          value: null,
          type: "range",
        },
        {
          label: "forms.level",
          name: "level",
          value: null,
          type: "text",
        },
        {
          label: "forms.employeeNo",
          name: "employee",
          value: null,
          type: "customSelect",
          resource: this.$Users,
          resourceType: "employee",
        },
        {
          label: "forms.skillNo",
          name: "skill",
          value: null,
          type: "customSelect",
          resource: this.$Skills,
          resourceType: "skill",
        },
        {
          label: "forms.category",
          name: "skill.category",
          value: null,
          type: "enum",
          enumName: "SKILL_CATEGORY",
        },
        {
          label: "forms.expiryDate",
          name: "expiryDate",
          value: null,
          type: "range",
        },
      ],
      advancedParams: [],
      columns: [
        {
          label: this.$t("forms.name"),
          field: "name",
          sortable: true,
        },
        {
          label: this.$t("forms.employeeNo"),
          field: "number",
          sortable: true,
        },
        {
          label: this.$t("forms.skillNo"),
          field: "skillNo",
          sortable: true,
        },
        {
          label: this.$t("forms.skill"),
          field: "skill",
          sortable: true,
        },
        {
          label: this.$t("forms.level"),
          field: "level",
          sortable: true,
        },
        {
          label: this.$t("forms.category"),
          field: "category",
          sortable: true,
        },
        {
          label: this.$t("forms.createdAt"),
          field: "createdAt",
          sortable: true,
        },
        {
          label: this.$t("forms.updatedAt"),
          field: "updatedAt",
          sortable: true,
        },
        {
          label: this.$t("forms.expiryDate"),
          field: "expiryDate",
          sortable: true,
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          sortable: false,
          width: "80px",
        },
      ],
      rows: [],
      skills: [],
      skillsLevels: [],
    };
  },
  created() {
    this.$EntityOptions.getCollection({ params: { type: ['STATUS'], cache: true, ttl: 300 } }).then((response) => {
      response.data["hydra:member"].forEach((status) => {
        if (status.name.includes('Active')) {
          this.activeStatus = {
            id: status['@id'],
            label: status.name,
            numberId: status.id,
          }
        }
      });
      this.load();
    });
  },
  methods: {
    load() {
      const params = this.getParams()
      this.$SkillMatrices
        .getCollection({ params }, "skill_matrix_list")
        .then((response) => {
          this.pages = response.data["hydra:totalItems"];
          this.rows = response.data["hydra:member"];
        });
    },
    getParams() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        "employee.status": this.activeStatus.id
      };
      params["order[" + this.sortable.order + "]"] = this.sortable.sort;
      let hasAdvancedFilter = false
      this.advancedParams.forEach((element) => {
        if (element.value !== "" && element.value !== null) {
          params[element.name] = element.value;
          hasAdvancedFilter = true
        }
      });
      if (typeof params.level === 'undefined') {
        params.level = ['Y','2','3','4']
      }
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      if ((this.search && this.search.length) || hasAdvancedFilter) {
        delete params.level
      }
      return params
    },
    setSort(offset, limit, order, sort) {
      this.sortable.order = order;
      this.sortable.sort = sort;

      if (this.sortable.order === 'name') {
        this.sortable.order = 'employee.givenName'
      }

      if (this.sortable.order === 'skill') {
        this.sortable.order = 'skill.skill'
      }

      if (this.sortable.order === 'skillNo') {
        this.sortable.order = 'skill.skillNo'
      }

      if (this.sortable.order === 'category') {
        this.sortable.order = 'skill.category'
      }

      if (this.sortable.order === 'number') {
        this.sortable.order = 'employee.number'
      }

      this.load();
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.load();
    },
    onChangePage(page) {
      this.currentPage = page;
      this.load();
    },
    deleteResource(url) {
      this.deleteByUrl(
        this.$SkillMatrices,
        url,
        this.$t('messages.skillMatrixDeleted'),
        null,
        this.load,
        this.error
      );
    },
    onPageChange() {
      this.load();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.load();
    },
    error() {
      this.$emit("clearAction");
    },
    setAdvancedParams(data) {
      this.advancedParams = data;
      this.load();
    },
    exportSkillMatrices() {
      const params = this.getParams();
      params.pagination = false;
      this.$SkillMatrices
        .getCollection({ params }, "skill_matrix_export")
        .then((response) => {
          if (response.status === 200) {
            const resources = [];
            response.data["hydra:member"].forEach((row) => {
              resources.push(row['@id'])
            });

            const body = {
              type: 'SKILL_MATRICES_EXCEL',
              fromDate: moment().format(),
              toDate: moment().format(),
              resources: resources
            };

            this.$Exports.createResource({ body })
                .then((response) => {
                  if (response.status === 200) {
                    const link = document.createElement("a");
                    link.href = response.data.notCachedUrl;
                    link.target = "_blank";
                    link.click();
                  }
                })
                .catch((error) => {
                  if (error.response) {
                    this.$helper.showToast(
                        useToast(),
                        Toast,
                        error.response.data["hydra:title"],
                        error.response.data["hydra:description"],
                        "danger"
                    );
                  }
                });
            }
        });
    }
  },
};
</script>

